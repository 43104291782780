@value flexColumnCenter from "../../../../common/styles/Layouts.module.css";
@value grey700, backgroundGrey200 from "../../../../common/styles/Theme.css";
@value text14, textRegular, textGrey700, lineHeight18 from '../../../../common/styles/Typography.module.css';
@value shadowLv4 from '../../../../common/styles/Shadows.module.css';

.container {
    composes: flexColumnCenter;
    composes: backgroundGrey200;
    composes: shadowLv4;
    position: absolute;
    opacity: 0;
    width: 13.5rem;
    border: 1px rgb(131,153,185) solid;
    padding: 35px;
}

.image img {
    height: 65px;
}

.text {
    composes: text14 textRegular textGrey700 lineHeight18;
    margin-top: 0.75rem;
}

.contactUs {
    margin-top: 1.5rem;
}

.close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.25rem;
    overflow: visible;
}

.close:hover,
.close:focus {
    color: grey700;
    cursor: pointer;
}

.tooltip {
    opacity: 1 !important;
    padding: 0 !important;
    border: 0 !important;
}