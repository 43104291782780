@value flexRow, flexColumn from '../../../../common/styles/Layouts.module.css';
@value grey450, red600 from "../../../../common/styles/Theme.css";
@value text14, text16, text18, textSemiBold, textBold, textGrey450, textGrey700, textRed600, letterSpacing2, lineHeight18,lineHeight23 from '../../../../common/styles/Typography.module.css';

.container {
    composes: flexColumn;
    align-items: center;
    width: 100%;
}

.list {
    width: 100%;
}

.list > .transitionList > :not(:first-child) {
    margin-top: 1.25rem
}

.list + .paginationWrapper, .paginationWrapper + .list {
    margin-top: 1.5rem;
}

.paginationWrapper {
    composes: flexColumn;
    position: relative;
    align-items: flex-start;
}

.total {
    composes: text16 textBold textGrey700 lineHeight23;
    /*position: absolute;
    white-space: nowrap;*/
}
.total + .pagination {
    margin-top: 0.5rem;
}

.pagination {
    composes: flexRow;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
}
.paginationWithMargin {
    composes: pagination;
    /*margin-top: 1.75rem;*/
}

.navigation {
    composes: flexRow;
    justify-content: flex-start;
    align-items: center;
}
.navigationLink {
    composes: flexRow;
    cursor: pointer;
    outline: none;
}
.navigation:first-child .navigationLink {
    padding-right: 0.25rem;
}
.navigation:last-child .navigationLink {
    padding-left: 0.25rem;
}
.navigation + .page .pageLinkActive {
    padding-left: 0;
}
.navigationLink svg {
    height: 16px;
    width: 10px;
}
.navigationDisabled {
    display: none !important;
}

.page {
    composes: flexRow;
    justify-content: flex-start;
    align-items: center;
}
.page + .page:before {
    content: '|';
    margin: 0.175rem 0 0.25rem 0;
    color: grey450;
}
.pageLink {
    composes: text14 textSemiBold textGrey450 lineHeight18;
    padding: 0 0.75rem;
    cursor: pointer;
    outline: none;
}
.pageLinkActive {
    composes: pageLink textRed600;
    pointer-events: none;
    /*margin-top: 2px;
    border-bottom: 2px solid red600;*/
}

.break {
    composes: flexRow;
    justify-content: flex-start;
    align-items: center;
}
.breakLink {
    composes: text16 textSemiBold textGrey450 letterSpacing2;
    cursor: pointer;
    outline: none;
}

.center {
    align-self: center;
}
.left {
    align-self: flex-start;
}
.right {
    align-self: flex-end;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}