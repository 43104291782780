@value labelWrapper, grey400, grey450, grey700, red600, backgroundWhite from "../../Theme.css";
@value text16, text18, textRegular, textGrey700, textGrey400, textGrey450, textRed600, textItalic, letterSpacingNormal, lineHeight24 from "../../Typography.module.css";
@value flexColumn from "../../Layouts.module.css";
@value m-1 from "../../Spaces.module.css";

.wrapper {}

.labelWrapper {
    display: block;
    margin-bottom: 0.25rem;
}

.label {}

.labelNote {
    composes: textGrey450 textItalic;
}

.inputWrapper {
    composes: backgroundWhite;
    position: relative;
    display: block;
    border: 1px solid grey400;
    padding: 0 1rem;
    height: 3rem;
}

.inputWrapper .placeholder {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    bottom: 0;
    font-size: 16px;
    font-weight: 400;
    color: grey450;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    z-index: 9;
}

.inputWrapper input:focus + .placeholder,
.inputWrapper input[required]:valid + .placeholder,
.inputWrapper input:not(:empty) + .placeholder {
    display: none;
}

.required {
    composes: text18 textRed600;
}

.optional {
    composes: textItalic;
}

.thick {
    height: 46px;
}

.input {
    composes: text16 textRegular textGrey700;
    position: relative;
    background: transparent;
    display: block;
    outline: none;
    border: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.inputWithError {
    border: 1px solid red600;
}

.error {
    composes: text16 textRegular textRed600 letterSpacingNormal;
    margin-top: 0.25rem;
}

