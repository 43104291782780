@value flexRow from "../../Layouts.module.css";
@value grey350 from "../../Theme.css";
@value text13, textRegular, textGrey700, lineHeight18 from "../../Typography.module.css";

.option {
    composes: flexRow;
    align-items: flex-start;
    cursor: pointer;
}

.title {
    composes: text13 textRegular textGrey700 lineHeight18;
    margin-left: 0.75rem;
}

.checkbox, .radio {
    composes: flexRow;
}