.contentItemsCenter {
    justify-content: center;
    align-items: center;
}
.contentItemsStart {
    justify-content: flex-start;
    align-items: flex-start;
}
.contentItemsEnd {
    justify-content: flex-end;
    align-items: flex-end;
}
.justifyStart {
    justify-content: flex-start;
    justify-items: flex-start;
}

.flexColumn {
    display: flex;
    flex-direction: column;
}
.flexColumnCenter {
    composes: flexColumn contentItemsCenter;
}
.flexColumnStart {
    composes: flexColumn contentItemsStart;
}
.flexColumnEnd {
    composes: flexColumn contentItemsEnd;
}

.flexRow {
    display: flex;
    flex-direction: row;
}
.flexRowCenter {
    composes: flexRow contentItemsCenter;
}
.flexRowStart {
    composes: flexRow contentItemsStart;
}
.flexRowStart {
    composes: flexRow contentItemsStart;
}
.flexRowEnd {
    composes: flexRow contentItemsEnd;
}