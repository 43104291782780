.enter {
    opacity: 0;
    transition: opacity 0ms ease;
}

.enterActive {
    opacity: 1;
    transition: opacity 0ms ease;
}

.exit {
    opacity: 1;
    transition: opacity 400ms ease;
}

.exitActive {
    opacity: 0;
    transition: opacity 400ms ease;
}
