@value shadowLv4 from '../../../../common/styles/Shadows.module.css';

.tooltip {
  composes: shadowLv4;
  opacity: 1 !important;
  box-shadow: shadow4;
  margin-top: -5px !important;
}

.divider {
  border-top: 1px solid #D2D2D4;
}

.item {
  cursor: pointer;
}

.firstItem {
  composes: item;
  padding: 0.5rem 1rem 0 1rem;  
}
.lastItem {
  composes: item;
  padding:  0 1rem 0.5rem 1rem;  

}
