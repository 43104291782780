@value flexColumn, flexColumnCenter, flexColumnStart from "../common/styles/Layouts.module.css";
@value backgroundGrey200 from "../common/styles/Theme.css";
@value shadowLv2 from "../common/styles/Shadows.module.css";

.pageContainer {
    composes: flexColumn;
    align-items: center;
    background-color: rgb(244,245,248);
    min-height: 100vh;
    /*overflow: auto;*/
}

.pageContent {
    max-width: 71.875rem;
    width: 95%;
}

.headerContainer {
    composes: flexColumnCenter;
    /*composes: shadowLv2;*/
    width: 100%;
    z-index: 10;
}

.contentContainer {
    composes: pageContent;
    composes: flexColumnStart;
    flex: 1;
}

.wideContentContainer {
    display: flex;
    width: 100%;
    flex: 1;
}

.footerContainer {
    composes: flexColumnCenter;
    width: 100%;
    z-index: 10;
}