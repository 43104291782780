@value flexColumnCenter, flexColumnStart from '../../../../common/styles/Layouts.module.css';
@value text16, text50, textMedium, textBold, textGrey700, textWhite, lineHeight24, lineHeight55 from '../../../../common/styles/Typography.module.css';

.baseWidth {
    max-width: 51rem;
    width: 90%;
}

.container {
    composes: flexColumnCenter;
    background: url("../../../../assets/images/profile_custom_analysis.png") top left;
    background-size: cover;
    padding: 7.25rem 0 6.25rem 0;
}

.content {
    composes: flexColumnStart;
    composes: baseWidth;
}

.title {
    composes: text50 textBold textWhite lineHeight55;
}

.title:hover .anchor {
    visibility: visible;
}

.anchor {
    cursor: pointer;
    margin-left: 0.75rem;
    visibility: hidden;
}

.description {
    composes: text16 textMedium textGrey700 lineHeight24;
    margin: 1rem 0 1.25rem 0;
    max-width: 22rem;
}