@value flexColumn, flexRow from '../../../common/styles/Layouts.module.css';
@value sm, md, lg from "../../../common/styles/Breakpoints.module.css";
@value blue600, red600, backgroundWhite from '../../../common/styles/Theme.css';
@value text7, text13, text14, text16, text18, text20, textRegular, textMedium, textBold, textGrey450, textGrey700, textRed600, letterSpacingNormal, lineHeight17, lineHeight24, lineHeight25 from '../../../common/styles/Typography.module.css';
@value m-0, m-t-2, p-2 from '../../../common/styles/Spaces.module.css';

@value pageContent from '../../Base.module.css';

.containerWrapper {
    composes: flexColumn;
    align-items: center;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.container {
    composes: pageContent;
    composes: flexColumn;
    align-items: flex-start;
    background-image: url("../../../assets/images/request_custom_analysis.svg");
    background-position: 85% 150px;
    background-size: 458px;
    background-repeat: no-repeat;
    padding: 2.5rem 0;
    height: 100%;
    width: 95%;
}

.container > div {
    max-width: 26rem;
    width: 100%;
    z-index: 1;
}

.content {
    composes: text16;
    position: relative;
    padding: 2.75rem 2rem 1.5rem 2rem;
}

.title {
    composes: text20 textBold textGrey700 lineHeight25;
}

.description {
    composes: text14 textRegular textGrey700 letterSpacingNormal lineHeight24;
    margin: 1rem 0 3rem 0;
}

.form {
    composes: flexColumn;
}

.disabledField {
    composes: flexColumn;
    margin-bottom: 0.25rem;
}
.disabledFieldLabel {
    composes: text7 textRegular textGrey450;
}
.disabledFieldValue {
    composes: text14 textMedium textGrey450 lineHeight24;
}

.txt {
    margin-top: 2.5rem !important;
}

.formNote {
    composes: flexRow;
    composes: text13 textRegular textRed600 lineHeight17;
    margin-top: 1.5rem;
}

.required {
    composes: text18 textRed600;
}

.completeText {
    composes: text16 textRegular textGrey700;
    composes: m-0;
    text-align: center;
    margin-bottom: 1.25rem;
}

.link,
.link:link,
.link:visited,
.link:hover,
.link:active {
    font-size: 16px;
    font-weight: 400;
    color: blue600;
    text-decoration: none;
    cursor: pointer;
}