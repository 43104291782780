@value flexColumn, flexRow, flexRowStart, flexRowCenter from '../../Layouts.module.css';
@value lg from "../../Breakpoints.module.css";
@value grey400, backgroundBlue600, white from '../../Theme.css';
@value text32, text14, textGrey400, textWhite from '../../Typography.module.css';
@value p-0 from '../../Spaces.module.css';

.container {
    composes: flexRowCenter;
    composes: textWhite backgroundBlue600;
    background-image: url(../../../assets/images/eversana_bg_logo.png);
    background-repeat: no-repeat;
    background-position: 10px -26px;
    padding: 2.5rem 0;
    width: 100%;
}

.container a:link, .container a:hover, .container a:visited {
    color: white;
    text-decoration: none;
}

.content {
    composes: flexColumn;
    width: 80%;
}

.logo img {
    width: 100%;
}

.logoDescription {
    composes: text14 textGrey400;
    line-height: 1.71;
    margin-top: 1.5rem;
    margin-left: 5%;
}

.branding {}

.navigation {
    margin-left: auto;
    margin-right: auto
}

.navigationMenu ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    font-size: 18px;
    list-style-type: none;
    margin: 1rem 0;
    padding: 0;
}

.navigationMenu ul li {
    margin: 0 0 10px;
    width: calc(100% / 3);
    padding: 0;
}

.socialBookmarks {
    composes: flexRow;
    composes: text32;
    composes: p-0;
    align-items: center;
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 0;
}

.socialBookmarks strong {
    font-size: 18px;
    text-transform: uppercase;
}

.socialBookmarks li {
    line-height: 100%;
    margin-bottom: 0;
    margin-right: 1rem;
}

.socialBookmarks img {
    height: 32px;
    width: 32px;
}

.socialBookmarks a {
    display: flex;
}

@media lg {

    .content {
        flex-direction: row;
        max-width: 71.875rem;
        width: 95%;
    }

    .logo {
        max-width: 436px;
        width: 45%;
    }

    .logo img {
        max-width: 300px;
    }

    .logo p {
        margin-left: 70px;
        max-width: 280px;
    }

    .navigation {
        margin-left: 10%;
        width: 45%;
    }
}