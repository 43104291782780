@value backgroundWhite, grey550, grey400 from "../../../../common/styles/Theme.css";
@value text16, textRegular, textBold, textGrey700 from "../../../../common/styles/Typography.module.css";

.container {
  composes: backgroundWhite;
  display: block;
  border: 1px solid grey400;
  padding: 0 0.5rem;
  height: 2.5rem;  
}

.input {
  composes: text16 textRegular textGrey700;
  background: transparent;
  display: block;
  outline: none;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

}

.input::placeholder {
  color: grey550;
}

.input:-webkit-autofill,
.input:-webkit-autofill:hover,
.input:-webkit-autofill:focus,
.input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}