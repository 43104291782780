/* Light */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/light/SuisseIntl-Light-WebS.ttf");
    font-weight: 200;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/light/SuisseIntl-Light-WebM.ttf");
    font-weight: 200;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/light/SuisseIntl-Light-WebXL.ttf");
    font-weight: 200;
    font-size: large;
}

/* Book */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/book/SuisseIntl-Book-WebS.ttf");
    font-weight: 300;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/book/SuisseIntl-Book-WebM.ttf");
    font-weight: 300;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/book/SuisseIntl-Book-WebXL.ttf");
    font-weight: 300;
    font-size: large;
}

/* Regular */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/regular/SuisseIntl-Regular-WebS.ttf");
    font-weight: 400;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/regular/SuisseIntl-Regular-WebM.ttf");
    font-weight: 400;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/regular/SuisseIntl-Regular-WebXL.ttf");
    font-weight: 400;
    font-size: large;
}

/* Regular & Italic */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/regular-italic/SuisseIntl-RegularItalic-WebS.ttf");
    font-weight: 400;
    font-style: italic;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/regular-italic/SuisseIntl-RegularItalic-WebM.ttf");
    font-weight: 400;
    font-style: italic;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/regular-italic/SuisseIntl-RegularItalic-WebXL.ttf");
    font-weight: 400;
    font-style: italic;
    font-size: large;
}

/* Medium */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/medium/SuisseIntl-Medium-WebS.ttf");
    font-weight: 500;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/medium/SuisseIntl-Medium-WebM.ttf");
    font-weight: 500;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/medium/SuisseIntl-Medium-WebXL.ttf");
    font-weight: 500;
    font-size: large;
}

/* SemiBold */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/semibold/SuisseIntl-SemiBold-WebS.ttf");
    font-weight: 600;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/semibold/SuisseIntl-SemiBold-WebM.ttf");
    font-weight: 600;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/semibold/SuisseIntl-SemiBold-WebXL.ttf");
    font-weight: 600;
    font-size: large;
}

/* SemiBold & Italic */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/semibold-italic/SuisseIntl-SemiBoldItalic-WebS.ttf");
    font-weight: 600;
    font-style: italic;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/semibold-italic/SuisseIntl-SemiBoldItalic-WebM.ttf");
    font-weight: 600;
    font-style: italic;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/semibold-italic/SuisseIntl-SemiBoldItalic-WebXL.ttf");
    font-weight: 600;
    font-style: italic;
    font-size: large;
}

/* Bold */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/bold/SuisseIntl-Bold-WebS.ttf");
    font-weight: 700;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/bold/SuisseIntl-Bold-WebM.ttf");
    font-weight: 700;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/bold/SuisseIntl-Bold-WebXL.ttf");
    font-weight: 700;
    font-size: large;
}

/* Bold & Italic */
@font-face {
    font-family: "Suisse";
    src: url("./fonts/bold-italic/SuisseIntl-BoldItalic-WebS.ttf");
    font-weight: 700;
    font-style: italic;
    font-size: small;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/bold-italic/SuisseIntl-BoldItalic-WebM.ttf");
    font-weight: 700;
    font-style: italic;
    font-size: medium;
}
@font-face {
    font-family: "Suisse";
    src: url("./fonts/bold-italic/SuisseIntl-BoldItalic-WebXL.ttf");
    font-weight: 700;
    font-style: italic;
    font-size: large;
}

body {
  margin: 0;
  font-family: Suisse, HelveticaNeue, "Helvetica Neue", Helvetica, Arial, "Liberation Sans", FreeSans, clean, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

html, body, #root {
    height: 100%;
}