@value flexColumn, flexRow, flexColumnCenter from '../../../common/styles/Layouts.module.css';
@value lg from  "../../../common/styles/Breakpoints.module.css";
@value text14, text20, text29, textBold, textRegular, textGrey700, lineHeight24, lineHeight25 from '../../../common/styles/Typography.module.css';

.container {
    composes: flexRow;
    width: 100%;
    flex: 1;
}

.headerTitle {
    composes: textRegular;
}

.headerTitleAbbreviation {
    composes: textBold;
}

.content {
    composes: flexRow;
    align-items: flex-start;
    margin: 3.125rem 0;
    width: 100%;
}

.filter {
    margin-bottom: 2rem;
}

.list {
    margin: 0 1.75rem;
    max-width: 58rem;
    width: 100%;
}

.blank {
    composes: filter;
    width: 530px;   /* width of expanded filter */
}

.centered {
    justify-content: center;
}

.spaced {
    justify-content: space-between;
}

.absolute {
    position: absolute;
    left: 0;
    z-index: 1;
}

.emptyList {
    composes: flexColumnCenter;
    padding: 5rem 0;
}

.image img {
    height: 104px;
}

.title {
    composes: text20 textBold textGrey700 lineHeight25;
    margin-top: 1.5rem;
}

.description {
    composes: text14 textRegular textGrey700 lineHeight24;
    text-align: center;
    margin-top: 0.75rem;
    max-width: 23rem;
}

.icon {
    display: inline-flex;
    vertical-align: middle;
    margin-right: 0.6rem;
}

.bold {
    composes: textBold;
}