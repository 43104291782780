@value flexColumnCenter from "../../../common/styles/Layouts.module.css";
@value sm, md, lg from "../../../common/styles/Breakpoints.module.css";
@value blue600, backgroundWhite from "../../../common/styles/Theme.css";
@value text16, text20, textRegular, textGrey700, lineHeight24 from '../../../common/styles/Typography.module.css';

.container {
    composes: flexColumnCenter;
    composes: text16 textRegular textGrey700 lineHeight24;
    composes: backgroundWhite;
    padding: 2rem;
    margin: 2rem 0;
    width: 100%;
}

.completeText {
    composes: text20 textGrey700;
    text-align: center;
    margin: 0;
}

.link,
.link:link,
.link:visited {
    color: blue600;
    text-decoration: none;
}
.link:hover,
.link:active {
    text-decoration: underline;
    cursor: pointer;
}

@media md {
    .container {
        width: 85%;
    }
}

@media lg {
    .container {
        width: 70%;
    }
}