@value flexColumn, flexColumnCenter, flexRowCenter from "../../Layouts.module.css";
@value sm, md, lg from  "../../Breakpoints.module.css";
@value white, blue700, backgroundWhite from "../../Theme.css";
@value text14, text16, text28, text29, textLight, textRegular, textMedium, textBold, textBlue700, textGrey400, textGrey700, textBlack, textUppercase, lineHeight24, lineHeight36 from "../../Typography.module.css";
@value shadowLv3 from "../../Shadows.module.css";
@value m-0, p-l-4, p-r-4 from "../../Spaces.module.css";

.container {
    composes: flexRowCenter;
    composes: backgroundWhite;
    composes: shadowLv3;
    background-image: linear-gradient(135deg, #003E8F 0%, #003E8F 0%, #003E8F 0%, #00214A 13%, #5E365B 24%, #C93473 50%, #EB8B23 69%, #FAC93D 86%, #E3B122 100%);
    background-size: 100% 5px;
    background-repeat: no-repeat;
    padding-top: 5px;
    width: 100%;
    height: 6rem;
}

.contentWrapper {
    composes: flexRowCenter;
    height: 100%;
    width: 100%;
}

.content {
    composes: flexColumn;
    position: relative;
    justify-content: center;
    align-self: center;
    text-align: center;
    max-width: 71.875rem;
    width: 95%;
}

.titleContainer {
    composes: flexColumn;
    justify-content: center;
}

.titleContainerWithOnlyTitle, .titleContainerWithBothTitles {
    composes: flexColumn;
    padding: 0 4rem;
}

.title {
    composes: text29 textBold textBlue700 lineHeight36;
    composes: m-0;
    text-align: center;
}

.subtitle {
    composes: text16 textRegular textBlack lineHeight24;
    margin: 0.5rem 0 0 0;
}

.title, .subtitle {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.separator {
    display: none;
}

.backActionContainer {
    composes: flexRowCenter;
    position: absolute;
    cursor: pointer;
}

@media md {
    .contentWrapper {
        max-width: 90rem;
    }
}

@media lg {

    .titleContainer {
        flex-direction: row;
    }

    .titleContainerWithBothTitles {
        flex-direction: row;
        justify-content: flex-start;
        padding: 0 0 0 16rem;
    }

    .separator {
        display: block;
        margin: 0.3rem 0.75rem;
        border: 1px blue700 solid;
    }
}