@value flexColumn from "../../Layouts.module.css";
@value p-t-2, p-b-2 from "../../Spaces.module.css";

.container {
    composes: flexColumn;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.content {}