@value red600, white, backgroundWhite, backgroundGrey325 from "../../../../common/styles/Theme.css";
@value shadowLv2 from "../../../../common/styles/Shadows.module.css";
@value text14, textBook, textBold, textGrey700, textRed600, textUppercase from "../../../../common/styles/Typography.module.css";
@value flexColumnCenter, flexRowCenter, contentItemsCenter from "../../../../common/styles/Layouts.module.css";

.tab {
  height: 100%;
  box-sizing: border-box;
  composes: flexColumnCenter;
  /* padding: 0rem 4.25rem; */
}

.active {
  composes: backgroundWhite;
  composes: shadowLv2;
  border-bottom: 2px red600 solid;

}

.labelContainer {
  width: 23.75rem;  
  composes: flexRowCenter;
}

.label {
  composes: text14 textBold textBook textGrey700 textUppercase;
}

.labelActive {
  composes: label;
  composes: textRed600;
}

.icon {
  margin-right: 0.5rem;
  composes: flexColumnCenter;
}

.icon svg {
  height: 14px;
  width: auto;
}

.link {
  height: 100%;
}