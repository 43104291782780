@value flexColumn from "../Layouts.module.css";
@value textBold, textGrey450, textGrey700, textLineThrough, lineHeight24 from "../Typography.module.css";

.container {
    composes: flexColumn;
}

.label {
    composes: textBold textGrey700 lineHeight24;
}

.label + .content {
    margin-top: 0.25rem;
}

.content {
    white-space: pre-line;
    padding: 0 0.25rem;
    margin: 0 -0.25rem;
}

.contentMatch {
    composes: content;
    background-color: rgb(200,237,255);
}

.contentNonMatch {
    composes: content;
    composes: textGrey450 textLineThrough;
}

.contentNA {
    composes: content;
}