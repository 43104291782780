@value flexRow, flexColumn, flexColumnStart, flexRowCenter, contentItemsCenter, contentItemsStart, contentItemsEnd from "../../../../common/styles/Layouts.module.css";
@value backgroundWhite, backgroundGrey325 from "../../../../common/styles/Theme.css";
@value shadowLv2 from "../../../../common/styles/Shadows.module.css";

.container {
  width: 100%;
}

.row {
  composes: flexRowCenter;
  composes: backgroundGrey325;
  composes: shadowLv2;
  /* padding: 0.5rem 0 0.5rem 2rem; */
  height: 3.25rem;
  overflow-x: hidden;

}


