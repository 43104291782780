@value grey320, grey350, grey400, grey450, grey600, grey700, red, red100, red600, red700, green500, green600, blue600, blue700, black, white from "Theme.css";

.text7  { font-size: 7px; }
.text8  { font-size: 8px; }
.text10 { font-size: 10px; }
.text11 { font-size: 11px; }
.text12 { font-size: 12px; }
.text13 { font-size: 13px; }
.text14 { font-size: 14px; }
.text15 { font-size: 15px; }
.text16 { font-size: 16px; }
.text18 { font-size: 18px; }
.text20 { font-size: 20px; }
.text23 { font-size: 23px; }
.text24 { font-size: 24px; }
.text26 { font-size: 26px; }
.text28 { font-size: 28px; }
.text29 { font-size: 29px; }
.text32 { font-size: 32px; }
.text45 { font-size: 45px; }
.text50 { font-size: 50px; }
.text55 { font-size: 55px; }

.textLight     { font-weight: 200; }
.textBook      { font-weight: 300; }
.textRegular   { font-weight: 400; }
.textMedium    { font-weight: 500; }
.textSemiBold  { font-weight: 600; }
.textBold      { font-weight: 700; }

.textItalic    { font-style: italic; }

.textGrey320   { color: grey320; }
.textGrey350   { color: grey350; }
.textGrey400   { color: grey400; }
.textGrey450   { color: grey450; }
.textGrey600   { color: grey600; }
.textGrey700   { color: grey700; }
.textBlue600   { color: blue600; }
.textBlue700   { color: blue700; }
.textRed       { color: red; }
.textRed100    { color: red100; }
.textRed600    { color: red600; }
.textRed700    { color: red700; }
.textGreen500  { color: green500; }
.textGreen600  { color: green600; }
.textBlack     { color: black; }
.textWhite     { color: white; }

.textCapitalize     { text-transform: capitalize; }
.textUppercase      { text-transform: uppercase; }
.textUnderline      { text-decoration: underline; }
.textLineThrough    { text-decoration: line-through; }

.letterSpacing03        { letter-spacing: 0.3px; }
.letterSpacing027       { letter-spacing: 0.27px; }
.letterSpacing043       { letter-spacing: 0.43px; }
.letterSpacing05        { letter-spacing: 0.5px; }
.letterSpacing1         { letter-spacing: 1px; }
.letterSpacing2         { letter-spacing: 2px; }
.letterSpacing3         { letter-spacing: 3px; }
.letterSpacingNormal    { letter-spacing: normal; }

.lineHeight14       { line-height: 14px; }
.lineHeight17       { line-height: 17px; }
.lineHeight18       { line-height: 18px; }
.lineHeight20       { line-height: 20px; }
.lineHeight22       { line-height: 22px; }
.lineHeight23       { line-height: 23px; }
.lineHeight24       { line-height: 24px; }
.lineHeight25       { line-height: 25px; }
.lineHeight27       { line-height: 27px; }
.lineHeight30       { line-height: 30px; }
.lineHeight35       { line-height: 35px; }
.lineHeight36       { line-height: 36px; }
.lineHeight46       { line-height: 46px; }
.lineHeight55       { line-height: 55px; }
.lineHeight69       { line-height: 69px; }