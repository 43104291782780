@value text14, text20, text29, textBold, textRegular, textGrey700, lineHeight24, lineHeight25 from '../../../common/styles/Typography.module.css';
@value flexRow, flexRowCenter, flexColumn, flexColumnCenter from '../../../common/styles/Layouts.module.css';

.headerTitle {
  composes: textRegular;
}

.headerTitleAbbreviation {
  composes: textBold;
}

.container {
  composes: flexColumnCenter;
  width: 100%;
}

.searchList {
  margin-top: 5rem;
  composes: flexRowCenter;
}

.title {
  composes: text20 textBold textGrey700 lineHeight25;
  margin-top: 2rem;
}

.description {
  composes: text14 textBold textGrey700 lineHeight24;
  max-width: 26rem;
  margin: 1.5rem -0.25rem 1rem 0;

}