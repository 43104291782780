.m-0    { margin: 0; }
.m-1    { margin: 1rem; }
.m-2    { margin: 2rem; }
.m-3    { margin: 3rem; }
.m-4    { margin: 4rem; }
.m-5    { margin: 5rem; }

.m-t-0  { margin-top: 0; }
.m-t-1  { margin-top: 1rem; }
.m-t-2  { margin-top: 2rem; }
.m-t-3  { margin-top: 3rem; }
.m-t-4  { margin-top: 4rem; }
.m-t-5  { margin-top: 5rem; }
.m-t-6  { margin-top: 6rem; }
.m-t-7  { margin-top: 7rem; }
.m-t-8  { margin-top: 8rem; }

.m-r-0  { margin-right: 0; }
.m-r-1  { margin-right: 1rem; }
.m-r-2  { margin-right: 2rem; }

.m-l-0  { margin-left: 0; }
.m-l-1  { margin-left: 1rem; }
.m-l-2  { margin-left: 2rem; }
.m-l-3  { margin-left: 3rem; }

.m-b-0  { margin-bottom: 0; }
.m-b-1  { margin-bottom: 1rem; }
.m-b-2  { margin-bottom: 2rem; }

.m-l-r-1 {
    margin-left: 1rem;
    margin-right: 1rem;
}

.p-0    { padding: 0; }
.p-1    { padding: 1rem; }
.p-2    { padding: 2rem; }
.p-3    { padding: 3rem; }
.p-4    { padding: 4rem; }
.p-5    { padding: 5rem; }

.p-t-0  { padding-top: 0; }
.p-t-1  { padding-top: 1rem; }
.p-t-2  { padding-top: 2rem; }
.p-t-3  { padding-top: 3rem; }
.p-t-4  { padding-top: 4rem; }
.p-t-5  { padding-top: 5rem; }
.p-t-6  { padding-top: 6rem; }
.p-t-7  { padding-top: 7rem; }
.p-t-8  { padding-top: 8em; }

.p-r-0  { padding-right: 0; }
.p-r-1  { padding-right: 1rem; }
.p-r-2  { padding-right: 2rem; }
.p-r-3  { padding-right: 3rem; }

.p-l-0  { padding-left: 0; }
.p-l-1  { padding-left: 1rem; }
.p-l-2  { padding-left: 2rem; }
.p-l-3  { padding-left: 3rem; }

.p-b-0  { padding-bottom: 0; }
.p-b-1  { padding-bottom: 1rem; }
.p-b-2  { padding-bottom: 2rem; }
.p-b-3  { padding-bottom: 3rem; }
.p-b-4  { padding-bottom: 4rem; }
.p-b-5  { padding-bottom: 5rem; }
.p-b-6  { padding-bottom: 6rem; }

.p-l-r-1 {
    padding-left: 1rem;
    padding-right: 1rem;
}