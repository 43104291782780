@value flexRow, flexColumn, flexRowCenter, flexColumnStart from "../../../../common/styles/Layouts.module.css";
@value grey350, grey500, backgroundGrey200, backgroundGrey300 from "../../../../common/styles/Theme.css";
@value text12, text13, text14, text16, text20, textRegular, textSemiBold, textBold, textItalic, textBlue600, textGrey400, textGrey450, textGrey700, textWhite, textUppercase, textUnderline, letterSpacing027, lineHeight18, lineHeight22, lineHeight23, lineHeight30 from '../../../../common/styles/Typography.module.css';

.baseWidth {
    max-width: 46rem;
    width: 100%;
}

.topBar {
    composes: text12 textSemiBold textWhite lineHeight18;
    background-image: linear-gradient(132deg, #003E8F 0%, #C93473 0%, #EB8B23 41%, #F4AF32 71%, #FED628 100%);
    padding: 0.25rem 2.5rem;
}

.header {
    composes: flexRow;
    composes: baseWidth;
    align-items: center;
    justify-content: space-between;
    margin: 0 2.5rem;
}

.title {
    composes: text20 textBold textBlue600 lineHeight30;
}

.info {
    composes: flexRow;
    align-items: center;
    margin-left: 1.5rem;
}

.match {
    composes: text12 textSemiBold textGrey400 letterSpacing027 textUppercase;
    white-space: nowrap;
    margin-right: 1.5rem;
}

.favorite {
    composes: flexRow;
    align-items: center;
    cursor: pointer;
}
.favoriteIcon {
    display: flex;
}
.favoriteText {
    composes: text13 textSemiBold textGrey400 letterSpacing027 textUppercase;
    margin-left: 0.25rem;
}

.preview {
    composes: baseWidth;
    margin: 1.25rem 2.5rem 2rem 2.5rem;
    padding-top: 1rem;
    border-top: 1px grey500 solid;
}

.preview.expanded {
    margin-bottom: 0;
}

.preview > .section:not(:first-child), .content > .section:not(:first-child) {
    margin-top: 1rem;
}

.section {
    composes: flexColumn;
}

.sectionTitle {
    composes: text16 textBold textGrey700 lineHeight23;
    composes: backgroundGrey300;
    padding: 0.15rem 0.75rem;
    margin: 0 -0.75rem 0.35rem -0.75rem;
}

.sectionContent {
    composes: text12 textRegular textGrey700 lineHeight18;
}

.contentWrapper {
    composes: flexRow;
    justify-content: center;
    width: 100%;
}

.content {
    composes: flexColumnStart;
    composes: baseWidth;
    margin: 1rem 2.5rem 0 2.5rem;
}
.content:empty {
    display: none;
}

.histogramTable th, .centeredTable th {
    font-style: italic;
    font-weight: 400;
}
.histogramTable th:nth-child(even), .histogramTable td:nth-child(even) {
    text-align: left;
}
.histogramTable th:nth-child(odd), .histogramTable td:nth-child(odd) {
    text-align: right;
}
.centeredTable td {
    text-align: center;
}
.histogramTable th, .histogramTable td, .centeredTable th, .centeredTable td {
    padding: 0.15rem 0.75rem;
    border: 1px grey350 solid;
}
.histogramTable th:first-child, .histogramTable td:first-child, .centeredTable th:first-child, .centeredTable td:first-child {
    font-weight: 600;
    border-left: 0;
}
.histogramTable th:last-child, .histogramTable td:last-child, .centeredTable th:last-child, .centeredTable td:last-child {
    border-right: 0;
}
.bulletedTable td, .bulletedListTable td {
    padding: 0;
    line-height: 20px;
}
/*.bulletedTable tr:not(:first-child) td, .bulletedListTable tr:not(:first-child) td {
    padding-top: 0.25rem;
}*/
.bulletedTable td:not(:first-child) {
    padding-left: 0.5rem;
}
/*.histogramTable + .tableLimit, .centeredTable + .tableLimit, */.bulletedTable + .tableLimit {
    padding-top: 0.25rem;
}
.bulletedTable + .tableLimit {
    border-top: 1px grey350 solid;
}
.bulletedTable + .tableLimit, .histogramTable + .tableLimit, .centeredTable + .tableLimit {
    margin-top: 0.25rem;
}
.tableLimit {
    composes: textGrey450 textItalic;
}

.footerWrapper {
    composes: flexRow;
    justify-content: center;
    border-top: 1px grey350 solid;
    width: 100%;
}

.footer {
    composes: flexColumn;
    composes: baseWidth;
    align-items: center;
    margin: 1.75rem 2.5rem;
}

.buttonGroup {
    composes: flexRow;
    justify-content: center;
}
.buttonGroup > a:not(:last-child) {
    margin-right: 2rem;
}

.expanded {}

.separator {
    margin-top: 2rem;
    width: 100%;
}

.paragraph {
    padding: 0.5rem 0 1.25rem 0;
}

.semiBold {
    composes: textSemiBold;
}
.bold {
    composes: textBold;
}

.centerAlign {
    text-align: center;
}
.leftAlign {
    text-align: left;
}
.rightAlign {
    text-align: right;
}

.disabled {
    pointer-events: none;
    opacity: 0.5;
}