@value grey700, backgroundGrey200 from "../Theme.css";
@value textGrey700, text18 from "../Typography.module.css";

.zeroOffsets {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.container {
    height: 100%;
    width: 100%;
    composes: zeroOffsets;
    overflow: hidden;
    position: fixed;
    z-index: 1000;
    display: table;
    background-color: rgba(0, 0, 0, 0.75);
}

.container > div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.contentWrapper {
    margin: auto;
    max-width: 450px;
    composes: backgroundGrey200;
    padding: 16px 0;
}
.content {
    margin: 0 32px;
    text-align: center;
}

.errorText {
    text-align: center;
    composes: textGrey700 text18;
}

.actions {
    margin-top: 16px;
    text-align: center;
}
.action {
    composes: text18;
}

.actions > a {
    margin: 16px 8px 0 8px;
}