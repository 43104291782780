@value flexRow, flexColumn, flexColumnStart from "../Layouts.module.css";
@value grey300, grey350, grey400, blue300, backgroundGrey300, backgroundGrey400, backgroundWhite, borderGrey350 from "../Theme.css";
@value text16, textRegular, textGrey700 from "../Typography.module.css";
@value shadowLv2 from "../Shadows.module.css";
@value m-t-1, p-r-1 from "../Spaces.module.css";

.dropdownWrapper {
    composes: flexColumnStart;
    composes: backgroundWhite;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.disabled {
    background-color: transparent;
    pointer-events: none;
    user-select: none;
}

.selectionContainer {
    composes: flexRow;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    border: 1.5px solid grey350;
    border-radius: 2px;
    height: 100%;
}

.selectionContainerInForm {
    border: none;
}

.selectionContainer > i {
    user-select: none;
}

.selectionText {
    composes: text16 textRegular textGrey700;
    padding: 0.5rem;
    cursor: pointer;
}

.selectionPlaceholderText {
    composes: text16 textRegular textGrey700;
    cursor: pointer;
}

.emptyText {
    letter-spacing: 1px;
}

.selectionIcon {
    composes: flexColumn;
    composes: p-r-1;
}

.optionsWrapper {
    position: relative;
    align-self: stretch;
    margin: 0 1px;
}

.optionsContainer {
    composes: flexColumnStart;
    composes: backgroundWhite;
    composes: shadowLv2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
}

.optionWrapper {
    align-self: stretch;
    cursor: pointer;
    min-height: 1.25rem;
}

.optionWrapper:not(:first-child) {
    border-top: 1.5px solid grey300;
}

.optionWrapper:hover {
    background-color: blue300;
}

.selectedOptionWrapper {
    composes: optionWrapper;
}

.optionText {
    composes: text16 textRegular textGrey700;
    composes: m-t-1;
}

.scrollThumbVertical {
    composes: backgroundGrey400;
    width: 10px;
}

.scrollTrackVertical {
    composes: backgroundGrey300;
    right: 0;
    bottom: 0;
    top: 0;
    width: 10px !important;
}