.enter {
    opacity: 0;
}

.enterActive {
    opacity: 1;
    transition: opacity 200ms linear;
}

.exit {
    opacity: 1;
}

.exitActive {
    opacity: 0;
    transition: opacity 200ms linear;
}