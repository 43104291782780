@value flexColumnCenter from '../../../../common/styles/Layouts.module.css';


.container {
  width: 100%;  
  max-width: 26rem;
  margin-right: -0.2rem;
}

.freeSpace {
  margin-bottom: 25rem;
}