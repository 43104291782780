@value grey100: rgb(249,249,249);
@value grey200: rgb(242,244,247);
@value grey300: rgb(236,237,238);
@value grey330: rgb(230,234,240);
@value grey325: rgb(242,243,247);
@value grey320: rgb(220,224,227);
@value grey350: rgb(210,210,212);
@value grey400: rgb(176,179,182);
@value grey450: rgb(125,127,131);
@value grey470: rgb(128,128,128);
@value grey500: rgb(104,108,113);
@value grey550: rgb(103,106,109);
@value grey600: rgb(51,51,51);
@value grey700: rgb(24,24,21);
@value blue200: rgb(229,234,240);
@value blue300: rgb(204,213,226);
@value blue400: rgb(168,194,227);
@value blue450: rgb(153,204,255);
@value blue600: rgb(0,47,108);
@value blue700: rgb(18,39,74);
@value blue800: rgb(13,30,58);

@value red:    rgb(255,0,0);
@value red100: rgb(249,25,58);
@value red200: rgb(247,231,238);
@value red600: rgb(175,22,86);
@value red700: rgb(150,15,72);

@value yellow300: rgb(249,243,163);
@value yellow500: rgb(253,237,0);
@value yellow600: rgb(238,225,0);
@value yellow700: rgb(225,214,0);

@value orange100: rgb(255,245,226);
@value orange200: rgb(255,202,101);
@value orange300: rgb(255,168,0);

@value green200: rgb(207,248,235);
@value green400: rgb(109,223,186);
@value green500: rgb(55,203,147);
@value green600: rgb(34,201,147);

@value black: rgb(0,0,0);
@value white: rgb(255,255,255);

.backgroundGrey100   { background-color: grey100; }
.backgroundGrey200   { background-color: grey200; }
.backgroundGrey300   { background-color: grey300; }
.backgroundGrey325   { background-color: grey325; }
.backgroundGrey330   { background-color: grey330; }
.backgroundGrey350   { background-color: grey350; }
.backgroundGrey400   { background-color: grey400; }
.backgroundGrey470   { background-color: grey470; }

.backgroundBlue200   { background-color: blue200; }
.backgroundBlue300   { background-color: blue300; }
.backgroundBlue400   { background-color: blue400; }
.backgroundBlue450   { background-color: blue450; }
.backgroundBlue600   { background-color: blue600; }

.backgroundRed200    { background-color: red200; }
.backgroundRed600    { background-color: red600; }
.backgroundRed700    { background-color: red700; }

.backgroundGreen400  { background-color: green400; }

.backgroundOrange200  { background-color: orange200; }

.backgroundWhite     { background-color: white; }

.borderGrey350       { border-color: grey350 }