@value flexRowCenter, flexColumnCenter from "../../Layouts.module.css";
@value sm, md, lg from "../../Breakpoints.module.css";
@value backgroundGrey200, blue600 from "../../Theme.css";
@value textGrey450, textBlue600, textRed600, textUnderline from "../../Typography.module.css";
@value p-3, m-t-2, m-b-3 from "../../Spaces.module.css";

.form {
    width: 100%;
}

.formItems {
    composes: flexColumnCenter;
    width: 100%;
}

.formItems > *:not(:first-child) {
    margin-top: 0.75rem;
}

.formItemFullWidth {
    width: 100%;
}

.formItemShorterWidth {
    width: 100%;
}

@media md {
    .formItemShorterWidth {
        width: 85%;
    }
}

@media lg {
    .formItemShorterWidth {
        width: 70%;
    }
}

.formRow {
    display: block;
    flex-direction: row;
}

.formRowCell {
    flex: 1 1 auto;
}

@media md {
    .formRow {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
        justify-content: space-between;
    }
    .formRowCell:not(:last-child) {
        margin-right: 2rem;
    }
}

.formButtons {
    display: flex;
    justify-content: center;
    margin-top: 1.75rem;
}

.formError {
    composes: textRed600;
}
.forgotPasswordText {
    text-align: center;
}
