@value flexRow from "../../Layouts.module.css";
@value grey350 from '../../Theme.css';
@value text14, textRegular, textItalic, textGrey450, textGrey700 from '../../Typography.module.css';

.container {
    width: 100%;
}

.container + .container {
    margin-top: 3rem;
}

.table {
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
}

.table thead .tr {
    vertical-align: bottom;
}

.table tbody .tr {
    vertical-align: top;
}

.table .td {}

.table .item {
    display: inline-flex;
}

.table .icon {
    margin-right: 1.25rem;
}

.table .multipleContent > :first-child {
    margin-top: 0.75rem;
}

.table .multipleContent > :not(:first-child) {
    margin-top: 1rem;
}

.limit {
    text-align: center;
}