@value text16, textRegular, textBold, textGrey700 from '../../../../common/styles/Typography.module.css';
@value flexRow, flexColumn, flexColumnStart, flexColumnCenter from '../../../../common/styles/Layouts.module.css';
@value grey300, grey350, grey400, blue300, backgroundGrey300, backgroundGrey400, backgroundWhite, borderGrey350, backgroundGrey470 from "../../../../common/styles/Theme.css";
@value shadowLv2 from "../../../../common/styles/Shadows.module.css";
@value m-t-1, p-r-1 from "../../../../common/styles/Spaces.module.css";

.optionsWrapper {
  position: relative;
  align-self: stretch;
  margin: 0 1px;
}

.optionsContainer {
  composes: flexColumnStart;
  composes: backgroundWhite;
  composes: shadowLv2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.optionWrapper {
  align-self: stretch;
  cursor: pointer;
  max-height: 2.5rem;

  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}

.optionWrapper:not(:first-child) {
  border-top: 1.5px solid grey300;
}

.optionWrapper:hover {
  background-color: blue300;
}

.selectedOptionWrapper {
  composes: optionWrapper;
}

.optionText {
  composes: text16 textRegular textGrey700;
  composes: m-t-1;
}

.scrollThumbVertical {
  composes: backgroundGrey470;
  border-radius: 3.5px;
  width: 7px;
}

.scrollTrackVertical {
  composes: backgroundWhite;
  right: 0;
  bottom: 0;
  top: 0;   
  width: 7px !important;
  
}
