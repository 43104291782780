@value flexRow, flexColumn from "../../Layouts.module.css";
@value grey350 from "../../Theme.css";
@value text14, textMedium, textBold, textGrey400, textGrey700, textBlue600, textUppercase, lineHeight20, lineHeight23 from "../../Typography.module.css";

.container {
    border-bottom: 1px grey350 solid;
}

.selection {
    composes: flexRow;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 0;
    cursor: pointer;
}

.placeholder {
    composes: text14 textBold textGrey700 lineHeight23;
}
.selectedInfo {
    white-space: nowrap;
    margin-left: 0.3rem;
}
.selectionIcon {
    display: flex;
    align-items: center;
    margin-left: 0.5rem;
}

.list {
    border-top: 1px grey350 solid;
    padding: 2rem 0 1.75rem 0;
}
.list .actions:first-child {
    margin-bottom: 2rem;
}
.list .actions:last-child {
    margin-top: 2rem;
}

.actions {
    composes: flexRow;
    justify-content: space-between;
}
.action {
    composes: flexRow;
    composes: text14 textMedium textUppercase lineHeight20;
    align-items: center;
    flex: 1;
}
.action svg {
    margin-left: 0.5rem;
}
.clearAction {
    composes: action;
    justify-content: flex-start;
}
.closeAction {
    composes: action;
    justify-content: flex-end;
}
.activeAction {
    composes: textBlue600;
    cursor: pointer;
}
.inactiveAction {
    composes: textGrey400;
}

.options {
    composes: flexColumn;
    align-items: flex-start;
}
.options > *:not(:first-child) {
    margin-top: 1rem;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}