@value flexRowCenter, flexColumnCenter from "../Layouts.module.css";
@value grey100, grey400, white, red600, blue200, blue600, blue700, red700, backgroundBlue600, backgroundRed600, backgroundWhite from "../Theme.css";
@value text12, text14, textMedium, textWhite, textRed600, textBlue600, textGrey700, letterSpacing03, letterSpacing05, lineHeight18, textUppercase from "../Typography.module.css";

/* General Config */
.container {
    composes: textMedium textGrey700 letterSpacing05 lineHeight18 textUppercase;
    border: none;
    outline: none;
    cursor: pointer;
}

.vertical   { composes: flexColumnCenter; }
.horizontal { composes: flexRowCenter; }

.text {
    text-align: center;
}

.icon {
    display: flex;
    align-items: center;
}

.loadingIcon {
    composes: flexColumnCenter;
    margin-right: 0.75rem;
    height: 1rem;
}

.xsmallIcon,    .xsmallIcon svg     { height: 8px;  }
.smallIcon,     .smallIcon svg      { height: 10px; }
.xnormalIcon,   .xnormalIcon svg    { height: 12px; }
.normalIcon,    .normalIcon svg     { height: 14px; }
.xlargeIcon,    .xlargeIcon svg     { height: 16px; }
.largeIcon,     .largeIcon svg      { height: 18px; }

.leftIcon.smallOffset   { margin-right: 0.25rem; }
.leftIcon.normalOffset  { margin-right: 0.5rem; }
.leftIcon.largeOffset   { margin-right: 0.75rem; }

.rightIcon.smallOffset   { margin-left: 0.25rem; }
.rightIcon.normalOffset  { margin-left: 0.5rem; }
.rightIcon.largeOffset   { margin-left: 0.75rem; }

.topIcon.smallOffset   { margin-bottom: 0.25rem; }
.topIcon.normalOffset  { margin-bottom: 0.5rem; }
.topIcon.largeOffset   { margin-bottom: 0.75rem; }

/* Primary config */
.primaryContainer {
    composes: container;
    composes: textWhite backgroundBlue600;
}
.primaryContainer.shadowed {
    box-shadow: 0 1px 4px 1px rgba(6,10,36,0.10), 0 5px 10px 0 rgba(6,10,36,0.08); /* shadowLv2 */
}
.primaryContainer.disabled {
    opacity: 0.3;
    pointer-events: none;
}
.primaryContainer:hover {
    background-color: blue700;
    box-shadow: 0 10px 20px 1px rgba(6,9,36,0.10), 0 24px 36px 0 rgba(6,10,36,0.18); /* shadowLv4 */
}
.primaryText {
    composes: text;
}
.primaryIcon {
    composes: icon;
}

/* Primary (alternative) config */
.primaryAltContainer {
    composes: container;
    composes: textWhite backgroundRed600;
}
.primaryAltContainer.shadowed {
    box-shadow: 0 1px 4px 1px rgba(6,10,36,0.10), 0 5px 10px 0 rgba(6,10,36,0.08); /* shadowLv2 */
}
.primaryAltContainer.disabled {
    opacity: 0.3;
    pointer-events: none;
}
.primaryAltContainer:hover {
    background-color: red700;
    box-shadow: 0 10px 20px 1px rgba(6,9,36,0.10), 0 24px 36px 0 rgba(6,10,36,0.18); /* shadowLv4 */
}
.primaryAltText {
    composes: text;
}
.primaryAltIcon {
    composes: icon;
}

/* Secondary config */
.secondaryContainer {
    composes: container;
    composes: textBlue600 backgroundWhite;
}
.secondaryContainer.shadowed {
    box-shadow: 0 1px 4px 1px rgba(6,10,36,0.10), 0 5px 10px 0 rgba(6,10,36,0.08); /* shadowLv2 */
}
.secondaryContainer.disabled {
    color: grey400;
    box-shadow: 0 1px 2px 1px rgba(6,10,36,0.10); /* shadowLv1 */
    pointer-events: none;
}
.secondaryContainer:hover {
    background-color: grey100;
    box-shadow: 0 10px 20px 1px rgba(6,9,36,0.10), 0 24px 36px 0 rgba(6,10,36,0.18); /* shadowLv4 */
}
.secondaryText {
    composes: text;
}
.secondaryIcon {
    composes: icon;
}
.secondaryContainer.disabled .secondaryIcon {
    opacity: 0.3;
}

/* Secondary (alternative) config */
.secondaryAltContainer {
    composes: container;
    composes: textBlue600 backgroundWhite;
    border: 1px solid blue600;
}
.secondaryAltContainer.shadowed {
    box-shadow: 0 1px 4px 1px rgba(6,10,36,0.10), 0 5px 10px 0 rgba(6,10,36,0.08); /* shadowLv2 */
}
.secondaryAltContainer.disabled {
    opacity: 0.3;
    box-shadow: none;
    pointer-events: none;
}
.secondaryAltContainer:hover {
    box-shadow: 0 10px 20px 1px rgba(6,9,36,0.10), 0 24px 36px 0 rgba(6,10,36,0.18); /* shadowLv4 */
}
.secondaryAltText {
    composes: text;
}
.secondaryAltIcon {
    composes: icon;
}

/* Secondary (alternative 2) config */
.secondaryAlt2Container {
    composes: container;
    composes: textRed600;
    outline-offset: -1px;
    outline: 1px solid red600;
}
.secondaryAlt2Container.shadowed {
    box-shadow: 0 1px 4px 1px rgba(6,10,36,0.10), 0 5px 10px 0 rgba(6,10,36,0.08); /* shadowLv2 */
}
.secondaryAlt2Container.disabled {
    box-shadow: none;
    pointer-events: none;
}
.secondaryAlt2Container:hover {
    box-shadow: 0 10px 20px 1px rgba(6,9,36,0.10), 0 24px 36px 0 rgba(6,10,36,0.18); /* shadowLv4 */
}
.secondaryAlt2Text {
    composes: text;
}
.secondaryAlt2Icon {
    composes: icon;
}

/* Link config */
.linkContainer {
    composes: container;
    composes: letterSpacing03;
}
.linkContainer.disabled {
    opacity: 0.3;
    pointer-events: none;
}
.linkText {
    composes: text;
}
.linkIcon {
    composes: icon;
}

/* Icon config */
.iconContainer {
    composes: container;
    height: 1.5rem;
    width: 1.5rem;
    padding: 0.5rem;
}
.iconContainer.shadowed:hover {
    box-shadow: 0 10px 20px 1px rgba(6,9,36,0.10), 0 24px 36px 0 rgba(6,10,36,0.18); /* shadowLv4 */
}
.iconContainer.disabled {
    opacity: 0.3;
    pointer-events: none;
}
.iconContainer:hover {
    border-radius: 50%;
    background-color: blue200;
}
.iconText {
    display: none;
}
.iconIcon {
    composes: icon;
    margin: 0;
}