@value flexColumn, flexRowCenter from "../Layouts.module.css";
@value lg from "../Breakpoints.module.css";
@value grey700, backgroundWhite from "../Theme.css";
@value text14, textBook, textGrey700, textUppercase from "../Typography.module.css";
@value shadowLv2 from "../Shadows.module.css";
@value m-l-2, p-l-1, p-l-r-1 from '../Spaces.module.css';

.container {
    composes: flexRowCenter;
    composes: backgroundWhite;
    composes: shadowLv2;
    z-index: 1;
    width: 100%;
    height: 5rem;
}

.content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 71.875rem;
    width: 95%;
}

.logo {}

.shadowed {
    composes: shadowLv2;
}

.menu {
    composes: backgroundWhite;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
}

.startMenuItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 0.5rem;
    width: 100%;
}

.startMenuItem {
    margin-top: 0.5rem;
}

.endMenuItems {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.endMenuItem {
    margin-top: 1rem;
    width: 90%;
}

.menuIcon svg {
    height: 2.5rem;
    cursor: pointer;
}

.burgerMenuButton {
    display: none;
}

@media lg {

    .menu {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .startMenuItems {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-top: 0;
        padding-left: 1rem;
        flex: 1;
    }

    .startMenuItem {
        margin-top: 0;
    }

    .endMenuItems {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    .endMenuItem {
        margin-top: 0;
        width: auto;
    }

    .endMenuItems .endMenuItem:not(:first-child) {
        margin-left: 2rem;
    }

    .menuIcon {
        display: none;
    }
}