@value flexRow, flexRowStart, flexColumn, flexColumnStart, flexColumnCenter from "../../../common/styles/Layouts.module.css";
@value grey350, grey700, blue600, backgroundGrey100 from "../../../common/styles/Theme.css";
@value text12, text13, text16, text26, text32, text50, textRegular, textSemiBold, textMedium, textBold, textGrey320, textGrey700, textWhite, textUppercase, letterSpacing027, lineHeight24, lineHeight35, lineHeight46, lineHeight55 from "../../../common/styles/Typography.module.css";
@value shadowLv2 from "../../../common/styles/Shadows.module.css";

.baseWidth {
    max-width: 51rem;
    width: 90%;
}

.container {
    composes: flexRowStart;
    position: relative;
    height: 100%;
    width: 100%;
}

.sidebarWrapper {
    composes: backgroundGrey100;
    composes: shadowLv2;
    position: absolute;
    height: 100%;
    z-index: 1;
}

.sidebar {
    composes: flexRow;
    min-height: 66px;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    transition: 0.5s;
}

.sidebarContainer {
    composes: flexRow;
    width: 100%;
}

.expandedWidth {
    width: 315px;
}
.collapsedWidth {
    width: 92px;
}

.sidebarHeader {
    composes: flexColumn;
    margin-left: 0.5rem;
}
.sidebarHeader a {
    display: flex;
    text-decoration: none;
}

.sidebarMenu {
    display: flex;
    cursor: pointer;
}
.sidebarMenu svg:not(:first-child) {
    margin-left: 0.5rem;
}

.sidebarContent {
    composes: flexColumn;
    padding-top: 1rem;
    padding-right: 0.5rem;
    overflow: auto;
    flex: 1;
}
.sidebarContent > * {
    margin-bottom: 1.25rem;
}
.sidebarContent > span:first-child {
    font-size: 16px;
    font-weight: 700;
    color: grey700;
    line-height: 23px;
}
.sidebarContent > span:not(:first-child) {
    font-size: 14px;
    color: blue600;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
}

.sidebarCollapsedContainer {
    composes: flexRow;
    justify-content: flex-end;
    padding: 1.5rem 1.5rem 0 1.5rem;
    width: 100%;
}
.sidebarExpandedContainer {
    composes: flexRow;
    justify-content: flex-end;
    padding: 1.5rem 1.5rem 1rem 2.5rem;
    width: 100%;
}

.profile {
    margin-left: 92px;
    width: 100%;
}

.content {
    composes: flexColumn;
    align-items: center;
    width: 100%;
}

.section {
    composes: flexColumn;
    align-items: center;
    width: 100%;
}

.section:not(.basicInfo):not(.insights):not(.customAnalysis) {
    padding: 5rem 0;
}

.section:not(.customAnalysis):nth-child(even) {
    border-top: 2px solid rgba(210,210,212,0.4);
    border-bottom: 2px solid rgba(210,210,212,0.4);
    background-color: rgba(0,47,108,0.04);
}

.sectionHeader {
    composes: baseWidth;
}
.sectionHeader:hover .sectionTitleAnchor {
    visibility: visible;
}
.sectionTitle {
    composes: text26 textBold textGrey700 lineHeight35;
}
.sectionTitleAnchor {
    margin-left: 0.75rem;
    visibility: hidden;
}

.sectionHeader + .sectionContent {
    margin-top: 1.75rem;
}

.sectionContent {
    composes: text16 textRegular textGrey700 lineHeight24;
    width: 100%;
}

.section:not(.basicInfo):not(.customAnalysis) .sectionContent {
    max-width: 51rem;
    width: 90%;
}

.insights {
    padding: 2.25rem 0;
}

.commonTable th, .simpleTable th {
    text-align: left;
}
.commonTable th, .commonTable td, .simpleTable th, .simpleTable td, .matrixTable td {
    padding: 0.65rem 0;
}
.commonTable th:not(:last-child), .commonTable td:not(:last-child), .simpleTable th, .simpleTable td {
    padding-right: 2rem;
}
.commonTable td {
    border-top: 1px grey350 solid;
    border-bottom: 1px grey350 solid;
}
.simpleTable td {
    border-top: 1px grey350 solid;
}
.simpleTable td td {
    border-top: 0;
    padding: 0;
}
.bulletedListTable td {
    padding: 0;
    line-height: 26px;
}

.tableLabel {
    font-weight: 600;
}
.tableContent {
    margin-top: 0 !important;
}

/*.commonTable thead + tbody tr:first-child td, .matrixTable tbody tr:first-child td {
    padding-top: 1.25rem;
}*/
.matrixTable td {
    border: 1px grey350 solid;
}
.matrixTable td:first-child {
    border-left: 0;
    padding-right: 0.875rem;
}
.matrixTable td:last-child {
    border-right: 0;
    padding-left: 0.875rem;
}

.hide {
    display: none !important;
}