@value flexRow, flexColumn, flexRowCenter from "../Layouts.module.css";
@value sm, md, lg, xl from "../Breakpoints.module.css";
@value grey100, blue200, backgroundBlue400 from "../Theme.css";
@value text14, textBook, textBold, textGrey400, textGrey700, textRed600, textUppercase from "../Typography.module.css";
@value roundDown4 from "../Borders.module.css";
@value p-l-3, p-r-3, m-r-2 from "../Spaces.module.css";

.container {
    width: 100%;
    align-items: stretch;
}

.topBar {
    width: 100%;
}

.header {
    composes: flexColumn;
    padding-top: 1.25rem;
}

.headerTopContainer {
    composes: flexRow;
    justify-content: center;
    width: 100%;
}

.headerBottomContainer {
    composes: flexRow;
    justify-content: center;
    width: 100%;
}

.pictureContainer {
    composes: m-r-2;
}

.titleContainer {
    composes: flexColumn;
    flex: 1;
}

.title, .subtitle {}

.headerClose {
    composes: flexRow;
    composes: text14 textBold textGrey400 textUppercase;
    align-items: center;
    cursor: pointer;
    transition: 0.6s;
}

.actionsContainer {
    composes: flexRowCenter;
}

.contentWrapper {
    width: 100%;
}
.content {
    composes: flexColumn;
    align-items: center;
}

.footer {
    composes: flexRowCenter;
    composes: text14 textBold textRed600 textUppercase;
    background-image: linear-gradient(180deg, grey100 0%, rgb(224,229,234) 100%);
    cursor: pointer;
}

.footer:hover {
    background-image: linear-gradient(180deg, rgb(244,245,246) 0%, rgb(224,229,234) 100%);
}

.headerClose svg, .footer svg {
    padding: 0.5rem;
}