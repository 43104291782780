@value text12, textMedium, textBlue600, textUppercase, letterSpacing043, lineHeight18 from "../../../common/styles/Typography.module.css";

.welcome {
    composes: text12 textMedium textBlue600 textUppercase letterSpacing043 lineHeight18;
    text-align: center;
}

.scrollToTop {
    position: fixed;
    height: 40px;
    width: 50px;
    right: 40px;
    bottom: 30px;
    cursor: pointer;
    opacity: 1;
    z-index: 1000;
}

.scrollToTop svg {
    height: 100%;
    width: 100%;
}