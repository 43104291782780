@value flexRowCenter from "../Layouts.module.css";
@value text16, textSemiBold, textBlue600, lineHeight24 from "../Typography.module.css";
@value p-2 from "../Spaces.module.css";

.container {
    composes: flexRowCenter;
}

.spinner {
    composes: text16 textSemiBold textBlue600 lineHeight24;
    letter-spacing: 0.3px;
}

.spinner > div {
    display: flex;
}

.fullWidth {
    width: 100%;
}

.fullHeight {
    height: 100%;
}