@value flexColumnCenter from "../../../../common/styles/Layouts.module.css";
@value text20, textBold, textGrey700 from "../../../../common/styles/Typography.module.css";
@value m-t-2, p-t-8 from "../../../../common/styles/Spaces.module.css";

.container {
    composes: flexColumnCenter;
    composes: p-t-8;
    width: 100%;
}

.title {
    composes: text20 textBold textGrey700;
}

.description {
    composes: text20 textGrey700;
    composes: m-t-2;
    text-align: center;
}