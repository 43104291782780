@value flexColumnCenter, flexRowCenter from "../Layouts.module.css";
@value md from "../Breakpoints.module.css";
@value grey700, backgroundGrey200 from "../Theme.css";
@value textGrey400, textGrey700, text18 from "../Typography.module.css";
@value p-r-1, p-t-5 from "../Spaces.module.css";

.zeroOffsets {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.container {
    composes: flexColumnCenter;
    composes: zeroOffsets;
    overflow: hidden;
    position: fixed;
    z-index: 1000;
}

.background {
    composes: zeroOffsets;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
}

.header {}

.contentWrapper {
    composes: flexColumnCenter;
    composes: backgroundGrey200;
    position: relative;
    overflow: auto;
    margin: 1rem;
    max-width: 90rem;
    max-height: calc(100vh - 10rem);
}
.content {
  composes: flexColumnCenter;
  margin: 2rem 4rem 2rem 3rem;
}

.close {
    composes: textGrey400;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0.5rem;
    overflow: visible;
}

.close:hover,
.close:focus {
    color: grey700;
    cursor: pointer;
}

.errorText {
  text-align: center;
  composes: textGrey700 text18;
}
.actions {
  composes: flexRowCenter;
}
.actions > div {
  margin: 1rem 0.5rem 0 0.5rem;
}