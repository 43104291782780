@value flexRow, flexColumn from '../../../../common/styles/Layouts.module.css';
@value grey350, grey400, grey700, backgroundGrey100, backgroundBlue600 from "../../../../common/styles/Theme.css";
@value text11, text14, text20, textRegular, textBold, textItalic, textGrey450, textGrey600, textGrey700, textWhite, lineHeight18, lineHeight23, lineHeight27 from '../../../../common/styles/Typography.module.css';

.container {
    composes: backgroundGrey100;
}

.header {
    composes: flexRow;
    composes: backgroundBlue600;
    justify-content: space-between;
    cursor: pointer;
}

.headerTitle {
    composes: text20 textBold textWhite lineHeight27;
}

.headerIcon {
    display: flex;
    align-items: center;
}

.headerIcon svg {
    width: 16px;
    height: 23px;
}

.content {
    composes: flexColumn;
}

.section {
    padding: 2.5rem 4.25rem 3rem 5.5rem;
}
.section:nth-child(2) {
    padding-top: 1.5rem;
}
.section:not(:last-child) {
    border-bottom: 1px grey400 solid;
}

.description {
    composes: text11 textRegular lineHeight18;
    color: rgb(103,106,109);
}
.description + div {
    margin-top: 1rem;
}

.bold {
    composes: textBold;
}

.field {
    composes: flexColumn;
}
.fieldGroup {
    composes: flexColumn;
    align-items: flex-start;
}

.customField {
    composes: field;
    composes: flexRow;
    align-items: center;
    align-self: flex-start;
    cursor: pointer;
}
.fieldGroup .field:not(:first-child) {
    margin-top: 1.25rem;
}
.customFieldIcon {
    composes: flexRow;
}
.customFieldLabel {
    composes: text14 textBold textGrey700 lineHeight23;
    margin-left: 0.75rem;
}

.actions {
    composes: flexRow;
    margin-top: 2rem;
}

.expandedWidth {
    width: 530px;
}
.expandedWidth .header {
    padding: 1.25rem 1.5rem 1.25rem 5.5rem;
}

.collapsedWidth {
    width: 140px;
}
.collapsedWidth .header {
    padding: 1.25rem 1.5rem 1.25rem 2rem;
}
.collapsedWidth .content {
    display: none;
}