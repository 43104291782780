@value labelWrapper, grey400, grey450, grey700, red600, backgroundWhite from "../../Theme.css";
@value text16, textRegular, textGrey700, lineHeight24 from "../../Typography.module.css";

@value lineHeight: 16px;
@value textFieldHeight: 40px;
@value thickTextFieldHeight: 46px;

.inputWrapper {
    position: relative;
    padding: calc((textFieldHeight - lineHeight) / 2) 1rem;
    height: 10rem;
}

.inputWrapper .placeholder {
    align-items: flex-start;
    top: 0.75rem;
}

.inputWrapper textarea:focus + .placeholder,
.inputWrapper textarea[required]:valid + .placeholder,
.inputWrapper textarea:not(:empty) + .placeholder {
    display: none;
}

.thick {
    padding: calc((textFieldHeight - lineHeight) / 2 + (thickTextFieldHeight - textFieldHeight) / 2) 1rem;
}

.input {
    composes: text16 textRegular textGrey700;
    line-height: 24px;
    resize: none;
}